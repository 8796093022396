.top-image {
    height: 100vh;
    min-height: 500px;
    .image {
        height: 100%;
        width: 100%;
        background: url(../img/top-background-1.jpg) top center no-repeat;
        background-size: cover;
        //img {
        //    max-width: 100%;
        //    max-height: 100%;
        //}
    }
}
