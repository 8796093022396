img {
    max-width: 100%;
    vertical-align: middle;
}

.clearfix {
    &:before {
        content: '';
        height: 0;
        clear: both;
        display: table;
    }
    &:after {
        content: '';
        height: 0;
        clear: both;
        display: table;
    }
}
