.header-navbar {
    margin-right: 30px;
    .md-nav-bar {
        height: 64px;
        .md-button {
            height: 64px;
            span {
                line-height: 40px;
            }
        }
        .md-button.md-unselected {
            color: #ffffff;
        }
    }
}

.site-header {
    position: fixed;
    z-index: 10;
    right: 0;
    top: 0;
    left: 0;
    .logo {
        cursor: pointer;
        color: #ffffff;
    }
}

.header-navigation {
    height: 64px;
    ul {
        margin: 0 15px 0 0;
        padding: 0;
        li {
            float: left;
            list-style-type: none;
            height: 64px;
            &.active a {
                background: darken(#03a9f4, 10%);
            }
            a {
                display: block;
                height: 64px;
                padding-left: 15px;
                padding-right: 15px;
                line-height: 64px;
                transition: all 0.3s ease-out;
                font-size: 16px;
                color: #ffffff;
                &:hover {
                    background: darken(#03a9f4, 10%);
                }
            }
        }
    }
}
